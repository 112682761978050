import NoResponsesSvg from "../../assets/images/no-responses.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./no-responses-recipients-view.styles";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);

const NoResponsesRecipientsView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.noResponsesRoot}>
      <img src={NoResponsesSvg} alt="no-responses" />
      <TypographyComponent styling={"h3"} boldness={"medium"} rootClassName={classes.noResponsesHeaderText}>
        {t("You have no survey questions to respond to.")}
      </TypographyComponent>
      <TypographyComponent styling={"p3"} rootClassName={classes.noResponsesSubHeaderText}>
        {t("Survey response for this vendor will")}
        <br />
        {t("appear when they share responses to an active survey.")}
      </TypographyComponent>
    </div>
  );
};

export default NoResponsesRecipientsView;
